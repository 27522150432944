<template>
	<v-container
			fluid
	>
		<Search types="accounts" ref="search" />

		<v-card-title>
			<v-spacer />
			<Button
				@click="add"
				class="mr-4"
			>
				<svg-icon icon="plus" />
			</Button>
			<Button
				color="primary"
				@click="save"
			>Save</Button>
		</v-card-title>

		<v-card>
			<v-card-text>
				<v-list
					v-sortable-list
					@sorted="sort"
				>
					<v-list-item v-for="(item, index) in items" :key="index" @click="()=>{}">

						<v-list-item-icon class="sortHandle">
							<v-icon>mdi-menu</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ item.at_name }}</v-list-item-title>
						</v-list-item-content>

						<v-list-item-icon class="sortHandle">
							<div @click="remove(item)">
								<svg-icon icon="trash-can" />
							</div>
						</v-list-item-icon>

					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>

	</v-container>
</template>

<script>
	import Sortable from 'sortablejs'
	import Search from "@/components/dialogs/Search"

	export default {
		components: {
			Search
		},
		data: () => ({
			items: [],
		}),
		directives: {
			sortableList: {
				bind(el, binding, vnode) {
					const options = {
						handle: ".sortHandle",
						animation: 150,
						onUpdate: event => vnode.child.$emit("sorted", event),
					}
					Sortable.create(el, options)
				},
			},
		},
		methods: {
			sort({ oldIndex, newIndex }) {
				const moved = this.items.splice(oldIndex, 1)[0]
				this.items.splice(newIndex, 0, moved)
			},
			remove(item) {
				this.items.splice(this.items.indexOf(item), 1)
			},
			add() {
				this.$refs.search.open().then(o => !this.items.find(a => a.at_name === o.at_name) && this.items.push(o))
			},
			save() {
				this.$httpInt.post(`/v2/app/account/suggestions`, { suggested_accounts: (this.items ?? []).map(o => o.at_name) })
					.then(res => this.$root.$snackbar('List updated: ' + res.data.result.added + ' added, ' + res.data.result.removed + ' removed'))
			},
			refreshContent () {
				this.$httpInt.get(`/v2/app/account/suggestions?per_page=1000`)
					.then(res => this.items = res.data.result)
			},
		},
		name: `CommunitySuggestions`,
		mounted () {
			this.refreshContent()
		},
	}
</script>